@import '../../shared/css/styles/mixins';

$main_bg_color: #f6f6f6;
$content_width: 870px;
$tintcolor: rgba(0, 0, 15, 0.27);
$foundation: #216093;
$inputFocusColor: #a1a1a1;

@mixin tint {
    background-color: $tintcolor;
}

html, body {
    width: 100%;
}

body {
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-smoothing: 'antialiased';
    background-color: $main_bg_color;
    display: block;
    
    color: #333;
    line-height: normal;

    .app-root>.loading {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;

        display: none;

        &.showing {
            @include flexbox;
            //-@include alignitems(center);
            @include justifycontent(center);
            padding-top: 10%;

            >div {
                text-align: center;
            }
        }
    }
}

a, .link {
    text-decoration: none;
    cursor: pointer;
    color: rgba(48, 48, 200, 1);

    &:hover {
        color: orange;
    }
}

b {
    font-weight: 600;
}

p {
    line-height: 1.4;
    padding: 15px 0px;
}

i {
    font-style: italic;
}

.janky-back-link {
    padding: 5px 0px;
}

select {
    cursor: pointer;
}

.select-with-chevron {
    position: relative;

    .select-chevron {
        position: absolute;
        right: 14px;
        top: 50%;
        //@include transform(translate(0, -50%));

        >span {
            color: rgba(0,0,0,.54);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid;
            //margin: 0 4px;
        }
    }
}

.nowrap {
    white-space: nowrap;
}

.text-align-center {
    text-align: center;
}

.profile-photo {
    @include border-radius(50%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    width: 84px;
    height: 84px;

    border: 2px solid $foundation;
}

input, textarea, select, .input {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    margin: 0;
    border: 1px solid rgba(175, 175, 175, 1);
    font-size: 20px;
    padding: 5px;
    width: 100%;
    text-overflow: ellipsis;
}

textarea {
    resize: none;
}

textinputfilter {
    >input {
        width: 100%;
    }
}

.grecaptcha-badge {
    // remember to make this visible at some point
    visibility: hidden;
}

.supercontainer {
    position: relative;

    top: 0;
    left: 0;

    opacity: 0;

    background-color: $main_bg_color;
    
    width: 100%;
    //min-height: 101vh;

    //overflow: hidden;

    //@include flexbox;
    //@include flexcol;

    &.showing {
        opacity: 1;
    }
}

.undergoing-maintenance {
    background-color: white;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;

    display: none;

    text-align: center;

    &.showing {
        @include flexbox;
        @include alignitems(center);
        @include justifycontent(center);
    }

    .graphic {
        padding-bottom: 32px;

        .fa.fa-wrench {
            font-size: 102px;
        }
    }

    .message {
        font-size: 14px;
        color: #a1a1a1;

        line-height: 1.4;

        p {
            padding: 0px;
        }
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    //height: 20px;
}
.lds-ellipsis div {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #a1a1a1;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}

body {
    >.loading {
        .lds-ellipsis {
            height: 64px;
            >div {
                top: 27px;
            }
        }
    }
}

.view {
    width: 100%;

    &.router-outlet {
        opacity: 0;

        &.showing {
            opacity: 1;
        }
    }
}

.button-holder {
    padding-top: 22px;

    .button {
        background-color: #313131;
        color: #fff;
        @include border-radius(3px);
        
        cursor: pointer;
        padding: 11px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
}
    
.button {
    background-color: #313131;
    color: #fff;
    @include border-radius(3px);
    @include user-select(none);
    
    cursor: pointer;
    padding: 11px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;

    display: block;

    >.working-display-content {
        position: relative;
        
        >.waiter {
            display: none;
            position: absolute;
            
            width: 52px;
            height: 6px;
            left: 49%;
            top: 50%;
            @include transform(translate(-50%, -50%));

            >div {
                background: #fff;
                width: 8px;
                height: 8px;
            }
        }
    }

    &.small {
        padding: 8px 9px;
        font-size: 13px;
    }

    &.bk-busy-true, &.bk-busy-true:hover, &.bk-busy-true:active {
        //background-color: #a1a1a1;
        //opacity: 0.95;
        
        >.working-display-content {
            >.waiter {
                display: block;
            }
            >.working-display-original-content {
                opacity: 0.00;
            }
        }
    }

    &.callout {
        @include border-radius(9px);
        padding: 15px;
        font-size: 16px;
        font-weight: 600;
    }

    &.plain {
        background-color: transparent;
        color: $foundation;
        border: 1px solid $foundation;

        &:hover {
            color: black;
            background-color: rgba($foundation, 0.075);
        }
    }

    &.primary {
        background-color: $foundation;
    }

    &.disabled {
        background-color: lightgrey;
        color: white;
        cursor: default;
    }
}

.bk-select {
    display: inline-block;
    position: relative;
    
    @include flexbox;

    >input, >.input {
        width: 100%;
    }

    >input.bk-select-display {
        text-overflow: ellipsis;
    }

    &.open {
        >input, >.input {
            &.bk-select-display {
                color: transparent;
            }
        }
    }

    &.closed {
        >.bk-select-list-holder {
            >input, >.input {
                &::selection {
                    opacity: 0;
                }
            }
        }
    }

    >.bk-select-list-holder {
        display: block;

        position: absolute;
        top: 0px;
        left: 0px;

        width: 100%;

        border: 1px solid transparent;

        &.open {
            background-color: #FFF;
            border-color: #eaecef;
            
            @include box-shadow( 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2) );
            z-index: 2;

            width: auto;
            min-width: 100%;

            >input {
                width: auto;
                min-width: 100%;
                opacity: 1;
            }
            >.bk-select-behind-auto-indicator {
                color: rgba(0, 0, 0, 0.35);
            }
            >input.bk-select-search-text {
                border-bottom: 1px solid #eaecef;
                color: inherit;
            }
            >.items {
                display: block;
            }
        }

        >input, >.input {
            width: 100%;
            border: none;
        }

        >input {
            opacity: 0;

            &:focus {
                opacity: 1;
            }
        }

        >.bk-select-behind-auto-indicator {
            background-color: #fff;
            color: transparent;
            position: absolute !important;
            top: 0;
            left: 0;
        }

        >input.bk-select-search-text {
            position: relative;
            background-image: none;
            background-color: transparent;
            color: transparent;
            opacity: 0;

            &:focus {
                opacity: 1;
                //outline: default;
                border: 1px solid $inputFocusColor;
            }
        }

        >.items {
            display: none;

            max-height: 150px;
            overflow: hidden;
            overflow-y: scroll;

            -webkit-overflow-scrolling: touch;

            .item {
                padding: 12px 8px;
                border-bottom: 1px solid #eaecef;
                display: none;
                outline: none;

                cursor: default;

                &.listed {
                    display: block;
                }

                &:hover, &:active, &:focus {
                    background-color: rgba(0, 0, 0, 0.035);
                }

                &:last-child {
                    border-bottom: none;
                }
                
                >.bk-select-address {
                    font-size: 14px;
                    line-height: 1.4;
                }
            }
        }
    }
}

.domcache {
    opacity: 0;
    overflow: hidden;
    height: 0px;
    width: 0px;
    @include user-select(none);
}

.bk-hidden {
    display: none !important;
}

.hi {
    font-size: 16px;
    font-weight: bold;
    @include flexbox;
    @include alignitems(center);

    .wee-avatar {
        margin-left: 10px;
        @include border-radius(50%);
        width: 27px;
        height: 27px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}

.stripe-elements-card {
    opacity: 0;
    &.ready {
        opacity: 1;
    }
}

.StripeElement {
    padding: 12px 11px;
    padding-right: 0px;
    line-height: 22px;
    
    border: 1px solid #ebebeb;
    @include border-radius(3px);

    /*
    font-size: 16px;
    color: #313131;

    input::placeholder {
        color: #a1a1a1;
        color: red;
    }
    */
}

.StripeElement--focus {
    border-color: #a1a1a1;
}

.StripeElement--invalid {
    border-color: crimson;
    color: crimson;
    //iconColor: '#fa755a'
}
/*
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
*/

form, .form {
    .inputs-sidebyeaches {
        @include flexbox;
        @include flexrowwrap;
        @include justifycontent(space-between);

        &.two-sidebyeaches {
            >.input-holder {
                @include flex(0 1 50%);
            }
        }

        &.three-sidebyeaches {
            >.input-holder {
                @include flex(0 1 33.3%);
            }
        }

        >.input-holder {
            padding-left: 3px;
            padding-right: 3px;

            &:first-child {
                padding-left: 0px;
            }
            &:last-child {
                padding-right: 0px;
            }
        }
    }

    .errorMsg {
        color: crimson;
    }

    .form-server-error-messages, .error-messages {
        padding: 15px;
        font-size: 14px;
        border: 2px solid crimson;
        background-color: rgba(175, 175, 175, 0.27);
        display: none;

        &.auto-added {
            margin-top: 15px;
        }

        &.showing {
            display: block;
        }

        .error.error-text, .error-message {
            padding-top: 5px;
            padding-bottom: 5px;

            &:first-child {
                padding-top: 0px;
            }
            &:last-child {
                padding-bottom: 0px;
            }
        }
    }

    .input-holder {
        position: relative;
        padding-top: 0px;
        padding-bottom: 11px;

        .input-label {
            color: #a1a1a1;
            font-size: 14px;
            padding: 0 11px;
            position: absolute;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 85%;

            line-height: 44px;

            @include user-select(none);
        }

        .input-label-above {
            color: grey;
            font-size: 13px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            line-height: 22px;
        }

        bk-select, bk-select-dialling-codes {
            display: block;
            position: relative;

            &:hover {
                input, .input {
                    border-color: #a1a1a1;
                }
            }
        }

        input, .input, select, textarea {
            position: relative;
            border: 1px solid #ebebeb;
            @include border-radius(4px);

            @include flexbox;
            @include alignitems(center);

            outline: none;

            background-color: transparent;

            &:focus, &:hover {
                border-color: $inputFocusColor;
            }

            &.disabled, &:disabled, &[disabled=true] {
                border-color: #ebebeb;
                background-color: rgba(0, 0, 0, 0.02);
                color: rgba(0, 0, 0, 0.38);
            }
        }

        input, .input, select {
            height: 44px;
            line-height: 22px;
        }

        textarea {
            resize: vertical;
            font-family: inherit;
        }

        select {
            color: #666;
        }

        input[type="checkbox"] {
            border: 1px solid #e1e1e1;
            @include border-radius(0px);

            height: 20px;
            width: 20px;
            position: relative;

            cursor: pointer;

            margin: 0 2px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }

            &:after {
                content: ' ';
            }

            &:active, &:checked:active {
            }

            &:checked {
                background-color: #ebebeb;
            }

            &:checked:after {
                //content: '\2714';

                position: absolute;
                top: 44%;
                left: 50%;
                
                width: 30%;
                height: 75%;

                border: solid #333;
                border-width: 0 2px 2px 0;
                      
                @include transform(translate(-50%, -50%) rotate(45deg));
            }
        }

        input[type="radio"] {
            border: 1px solid #e1e1e1;
            @include border-radius(50%);

            height: 20px;
            width: 20px;
            position: relative;

            cursor: pointer;

            margin: 0 2px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }

            &:after {
                content: ' ';
            }

            &:active, &:checked:active {
            }

            &:checked {
                background-color: #ebebeb;
            }

            &:checked:after {
                //content: '\2714';

                position: absolute;
                top: 50%;
                left: 50%;
                
                width: 69%;
                height: 69%;

                @include border-radius(50%);
                border: 2px solid #333;
                background-color: #555;

                
                      
                @include transform(translate(-50%, -50%) rotate(45deg));
            }
        }

        .input-customerinfo {
            margin: 5.5px 0;
            color: #a1a1a1;
            font-size: 12px;
            line-height: 16px;
        }

        .input-info {
            color: #666;
            margin-top: 4px;
            font-size: 12px;

            &.input-info-singleline {
                font-size: 13px;
                color: rgba(0, 0, 0, 0.65);
            }
        }


        &.checkbox-input-holder {
            @include flexbox;
            @include alignitems(center);
            line-height: 0;

            .checkbox-input-label {
                padding-left: 8px;
                font-size: 15px;
                font-weight: 400;

                @include flexbox;
                @include alignitems(center);


                &.muted {
                    font-weight: 600;
                    color: #a1a1a1;
                    font-size: 11px;
                    padding-left: 5px;
                }
            }
        }

        .sidebyeaches.phone {
            @include flexbox;

            >div {
                padding-left: 3px;
                padding-right: 3px;

                &:first-child {
                    padding-left: 0px;
                }
                &:last-child {
                    padding-right: 0px;
                }
            }

            >.phonenumber-input {
                @include flex(1 1 auto);
            }
        }
    }

    .validation-display {
        color: #666;
        
        font-size: 12px;
        
        display: none;

        &.error {
            padding-top: 4px;

            &.has-validation-error-key-required {
                .validation-display-element.validation-display-element-error.error {
                    display: none;
                    &.requirement-key-required {
                        display: block;
                    }
                }
            }
        }

        &.validation-display-hints {
            display: block;
            padding-top: 4px;
        }

        // background-color: rgba(100, 100, 100, 0.5);

        .validation-display-element {
            font-size: 13px;
            margin: 2px 0px;

            display: none;

            &.validation-display-element-hint {
                &.error, &.errored {
                    display: block;
                }
            }

            &.validation-display-element-error {
                
                &.error {
                    display: block;
                }
            }

            >div {
                @include flexbox;
                @include alignitems(center);

                .validation-display-icon {
                    @include flex(0 0 22px);

                    &.fa-check {
                        color: green;
                    }
                    &.fa-times {
                        color: crimson;
                    }
                    &.fa-exclamation-triangle {
                        color: orange;
                    }
                }
            }
        }
    }

    &.form-submission-attempted.error {
        .validation-display {
            display: block;
        }
        input, .input {
            &.error, &.error:focus {
                border-color: crimson;
            }
        }
        .StripeElement {
            &.error {
                border-color: crimson;
            }
        }
    }
}

.misc-customerinfo {
    margin: 5.5px 0;
    // color: #a1a1a1;
    color: #BBB;
    font-size: 12px;
    line-height: 16px;

    &.gift-is-tax-deductible {
        padding-bottom: 15px;
    }
}

.celebrate-someone {
    color: #a1a1a1;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
}

.donate {
    line-height: 22px;
    font-size: 14px;

    display: none;

    &.showing {
        display: block;
    }

    .header-holder {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);

        .header-bg {
            background-color: #fff;

            .header {
                @include flexbox;
                @include alignitems(center);
                @include justifycontent(center);

                padding: 22px 0px;
                margin: 0 auto;

                // width: $content_width;
                width: 100%;

                .header-inner {
                    position: relative;

                    .canada {
                        position: absolute;
                        bottom: -5px;
                        left: 50%;
                        @include transform(translate(-50%, 0%));
                        font-weight: 600;
                        font-size: 12px;
                        text-transform: uppercase;
                        color: red;
                    }
                }

                a {
                    >img {
                        max-width: 300px;
                        max-height: 80px;
                    }
                }

                span.platform {
                    font-size: 36px;
                    font-weight: 400;
                }
            }
        }
    }

    .checkout-holder {
        padding-top: 33px;

        .checkout-content {
            width: $content_width;
            margin: 0 auto;

            .checkout-section {
                width: calc(50% - 16.5px);
                margin: 0 auto;
                margin-bottom: 22px;
                background-color: #fff;
                padding: 22px;
                border: 1px solid #e4e4e4;
                @include border-radius(3px);

                &.checkout-section-large {
                    width: calc(80% - 16.5px);
                    min-width: 800px;
                }

                &.checkout-section-contribution {
                    .pill-choices-holder {
                        //padding-top: 22px;
                        padding-bottom: 22px;
                    }
                }

                .checkout-section-error {
                    margin: 15px 0px;
                    color: crimson;
                    padding: 15px;
                    border: 1px solid crimson;
                    @include border-radius(3px);
                }

                .checkout-section-title-holder {
                    @include flexbox;
                    @include alignitems(center);
                    @include justifycontent(space-between);

                    .checkout-section-edit-button {
                        >span {
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: 400;
                            color: #a1a1a1;
                        }
                    }
                }

                .checkout-section-title {
                    @include flexbox;
                    @include alignitems(center);

                    margin-top: 2px;
                    font-weight: 500;

                    color: #a1a1a1;

                    >span {
                        font-size: 23px;

                        &.num {
                            font-size: 22px;
                            margin-right: 11px;
                        }
                    }
                }

                .browser-payment-method-selected {
                    @include flexbox;
                    @include alignitems(center);
                    @include justifycontent(center);
                    
                    .logo-holder {
                        .logo {
                            width: 55px;
                            height: 45px;

                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: contain;

                            &.apple-pay {
                                background-image: url('/assets/apple-pay-logo.svg');
                            }
                            &.g-pay {
                                background-image: url('/assets/google-pay-mark.png');
                            }
                        }
                    }
                    .info {
                        padding-left: 15px;
                        color: #a1a1a1;
                        font-size: 14px;
                    }
                }

                .amount-input-selections {
                    padding-top: 2px;
                }

                .pill-choices-title {
                    font-size: 15px;
                    font-weight: 600;
                    padding-bottom: 10px;
                    color: #a1a1a1;
                }

                .pill-choices {
                    @include flexbox;
                    @include justifycontent(space-between);

                    position: relative;
                    border: 1px solid #f0f0f0;
                    border-radius: 100px;
                    background-color: #f0f0f0;
                    height: 35px;
                    width: 100%;

                    .pill-choice {
                        @include flex(1 1 auto);

                        padding: 0;
                        
                        text-align: center;
                        
                        border: none;
                        height: 33px;
                        line-height: 14px;
                        font-size: 14px;
                        font-weight: 400;
                        
                        @include flexbox;
                        @include justifycontent(center);
                        @include alignitems(center);

                        color: #767676;
                        cursor: pointer;

                        &.active {
                            border-radius: 100px;
                            background-color: #fff;
                        }
                    }

                    &.pill-choices-3 {
                        .pill-choice {
                            @include flex(0 0 33.333%);
                        }
                    }
                }

                .payment-form {

                    .payment-inputs {
                        padding-top: 11px;

                        .payment-relax-message {
                            font-size: 11px;
                            line-height: 22px;
                            color: #a1a1a1;
                        }
                    }

                    .billing-address-form {
                        .billing-address-form-title {
                            padding: 11px 0px;
                            font-size: 16px;
                        }
                    }
                }

                &.active {
                    .checkout-section-title-holder,
                    .checkout-section-subtitle-holder {
                        padding-bottom: 22px;
                    }
                    .checkout-section-title {
                        color: #313131;
                    }
                }

                &.completed {
                    .checkout-section-title {
                        color: #313131;
                    }
                }

                .checkout-section-top-message {
                    color: #a1a1a1;
                    font-size: 14px;
                    padding-bottom: 10px;
                }

                .checkout-section-detail-view {
                    padding-top: 22px;
                    font-size: 14px;

                    &.muted, .muted {
                        color: #a1a1a1;
                    }

                    &.checkout-section-detail-view-payment {
                        @include flexbox;
                        @include justifycontent(space-between);

                        .payment-details-box {
                            @include flex(0 0 50%);
                            .title {
                                font-weight: 600;
                            }
                            .more-details {
                                color: #a1a1a1;
                            }
                        }
                    }

                    &.checkout-section-detail-view-donor-info {
                        @include flexbox;
                        @include justifycontent(space-between);

                        .details-box {
                            .title {
                                font-weight: 600;
                            }
                            .more-details {
                                color: #a1a1a1;
                            }
                        }
                    }

                    .checkout-section-detail-payment-request {
                        @include flexbox;
                        @include alignitems(center);

                        .logo {
                            width: 55px;
                            height: 45px;

                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: contain;

                            &.apple-pay {
                                background-image: url('/assets/apple-pay-logo.svg');
                            }
                            &.g-pay {
                                background-image: url('/assets/google-pay-mark.png');
                            }
                        }

                        span {
                            padding-left: 15px;
                            color: #a1a1a1;
                        }
                    }
                }

                &.review-section {
                    .review-section-content {
                        .review-section-message {
                            color: #a1a1a1;
                            font-size: 14px;
                        }
                        >form {
                            padding-top: 22px;
                        }
                    }
                }

                .error-detail {
                    p {
                        padding: 3px 0px;
                    }
                }
                .error-detail-buttons {
                    @include flexbox;
                }
            }
        }
    }
}

.saved-payment-details {
    font-size: 14px;

    &.muted {
        color: #a1a1a1;
    }

    @include flexbox;
    @include justifycontent(space-between);

    .payment-details-box {
        @include flex(0 0 50%);
        .title {
            font-weight: 600;
        }
        .more-details {
            color: #a1a1a1;
        }
    }
}

.mpp-assignments-inputs {
    @include flexbox;
    @include alignitems(center);
    
    >input, >select {
        max-width: 50%;
        @include flex(1 1 1%);
    }
}


.poptint {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 100%;
    right: 100%;

    opacity: 0;

    @include tint;

    @include transition( bottom 0.0s 0.240s, right 0.0s 0.240s, opacity 0.160s 0.0s );

    &.showing {
        bottom: 0%;
        right: 0%;
        opacity: 1;
        @include transition( bottom 0.0s 0.0s, right 0.0s 0.0s, opacity 0.160s 0.0s );
    }
}

.popup, .cdk-overlay-container {
    >.contents, .mat-mdc-dialog-surface {
        padding: 0px 15px;
        padding-bottom: 25px;

        .title-holder {
            padding: 25px 0px;

            text-align: center;
            position: relative;

            .title {
                font-size: 23px;
            }

            .subtext {
                color: #a1a1a1;
                padding-top: 7px;
                font-size: 13px;
            }
        }

        .button-holder {
            padding-top: 35px;
        }

        .buttons {
            @include flexbox;
            @include justifycontent(center);
            
            padding-top: 25px;
            
            &.mid {
                padding: 25px 0px;
            }
        }

        .or {
            text-align: center;
            font-weight: 400;
            border-top: 1px solid rgba(230, 230, 230, 1);
            border-bottom: 1px solid rgba(230, 230, 230, 1);
            padding: 12px 0px;
            margin: 0 25px;
        }

        .something-else {
            padding-top: 22px;
            font-size: 14px;
            text-align: center;

            @include flexbox;
            @include alignitems(center);
            @include justifycontent(center);

            .separator {
                width: 20px;
            }
        }
    }
}

.under-password-input {
    @include flexbox;
    @include alignitems(center);
    @include justifycontent(space-between);

    >div {
        @include flex(1 1 auto);
    }

    .forgot-pass {
        text-align: right;
        padding-top: 5px;
        font-size: 13px;
    }
}

.popup {
    @include flex(1 0 auto);

    @include flexbox;
    @include flexcol;
    @include alignitems(center);
    //@include justifycontent(center);

    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    
    width: 0%;

    min-height: 100vh;

    padding: 0px;

    outline: none;

    @include transition( width 0.0s 0.240s, padding 0.0s 0.240s, opacity 0.200s 0.0s );

    //-@include force-hw-accel;

    opacity: 1;

    &.open {
        width: 100%;
        padding-bottom: 50px;
        padding-left: 15px;
        padding-right: 15px;

        @include transition( width 0.0s 0.0s, padding 0.0s 0.0s, opacity 0.240s 0.0s );

        &.showing {
            opacity: 1;
            //position: relative;
        }
    }

    &.closed {

    }

    &::before {
    //-.before-contents {
        display: block;
        width: 100%;
        height: 18vh;
        min-height: 50px;
        //@include flex(0.25 0 50px);
        content: "";
    }

    >.contents {
        @include flex(0 1 auto);

        &.showing {
            @include transition( opacity 0.240s );
        }

        min-width: 0;
        max-width: 420px;
        background-color: white;
        padding: 15px;
        
        //width: calc(75% - 16.5px);
        //margin: 0 auto;
        //margin-bottom: 22px;
        //background-color: #fff;
        padding: 22px;
        //border: 1px solid #e4e4e4;
        @include border-radius(3px);

        opacity: 0;

        min-width: 320px;

        &.showing {
            opacity: 1;
        }
    }
    form {
        width: 100%;
        min-width: 320px;
    }
}

.checkout-subscriptions {
    font-size: 15px;
    p {
        color: #7a7a7a;
    }
    .subscriptions-list {
        .title {
            font-weight: 600;
            padding-top: 12px;
            padding-bottom: 10px;
            color: #313131;
        }
        .subscription {
            border: 1px solid rgba(225, 225, 225, 1);
            background-color: #fdfdfd;
            @include border-radius(3px);
            padding: 10px;

            margin: 10px 0px;

            opacity: 0.45;

            &.active {
                opacity: 1;
            }

            color: #313131;

            line-height: 1.6;

            .main-data {
                @include flexbox;
                //@include alignitems(center);
                @include justifycontent(space-between);

                .details {
                    .started {
                        font-size: 12px;
                        color: #a1a1a1;
                    }
                }
            }

            .amount-interval {
                font-weight: 600;
                font-size: 18px;

                >span>span {
                    color: #555;
                    font-size: 14px;
                }
            }

            .who-to {
                font-size: 15px;
                //font-weight: 600;
                color: #555;
                text-decoration: underline;
            }

            .who-for {
                padding: 50px 0px;
                font-size: 16px;
                font-weight: 400;

                color: #a1a1a1;
                
                text-align: center;
                
                span {
                    color: #555;
                    font-weight: 600;
                }
            }

            .control-buttons {
                padding-top: 0px;
                @include flexbox;
                //@include justifycontent(center);

                &.pad {
                    padding-top: 50px;
                }

                .button {
                    //padding: 5px 10px;

                    &.cancel {
                        background-color: #333;
                    }
                }
            }
        }
    }
}

.platform-view-component {
    padding: 15px;
    padding-bottom: 55px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
    app-edit-email-template {
        flex: 1 0 100%;
        
        display: flex;
        flex-direction: column;

        .edit-email-template-component-internal-root {
            flex: 1 0 100%;
            display: flex;
            flex-direction: column;

            .panels {
                flex: 1 0 100%;
                display: flex;

                >.panel {
                    flex: 0 0 50%;
                    background-color: white;
                    
                    //overflow-x: scroll;

                    border: 3px solid #666;

                    //&.edit-panel {
                        //white-space: pre;
                        //overflow: hidden;
                    //}

                    //&.view-panel {
                        //overflow-x: scroll;
                    //}
                }
            }
        }
    }
}

.add-something-dialog {
    .add-something-dialog-available-things {
        .title {
            padding-bottom: 25px;
        }

        .add-thing-selector {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 15px;
            margin-bottom: 13px;
            border: 1px solid lightgrey;
        }
    }
}

.bk-select-organization {
    .bk-select-display {
        color: #666;
    }
}
