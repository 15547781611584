$fa-font-path:                "../../../fonts/fa"; // must be relative to the file it's being called in.

@import '../node_modules/ngx-sharebuttons/themes/default';

@import '../../shared/css/styles/reset';
@import '../../shared/css/styles/mixins';
@import '../../shared/css/styles/fontawesome';

@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

@import './main.scss';
@import './breakpoints.scss';
