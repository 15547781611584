@mixin opacity($opacity) {
    filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
    opacity: $opacity;
}

@mixin box-sizing($bs) {
    $bs: unquote($bs);
    -webkit-box-sizing: $bs;
    -moz-box-sizing: $bs;
    -ms-box-sizing: $bs;
    -o-box-sizing: $bs;
    box-sizing: $bs;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}
@mixin border-top-right-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-top-right-radius: $radius;
    -ms-border-top-right-radius: $radius;
    -o-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
}
@mixin border-top-left-radius($radius) {
    -webkit-border-top-left-radius: $radius;
    -moz-border-top-left-radius: $radius;
    -ms-border-top-left-radius: $radius;
    -o-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
}
@mixin border-top-radius($radius) {
    @include border-top-right-radius($radius);
    @include border-top-left-radius($radius);
}
@mixin border-bottom-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-bottom-left-radius: $radius;
    -ms-border-bottom-left-radius: $radius;
    -o-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
}
@mixin border-bottom-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-bottom-right-radius: $radius;
    -ms-border-bottom-right-radius: $radius;
    -o-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
    @include border-bottom-right-radius($radius);
    @include border-bottom-left-radius($radius);
}
@mixin border-left-radius($radius) {
    @include border-top-left-radius($radius);
    @include border-bottom-left-radius($radius);
}
@mixin border-right-radius($radius) {
    @include border-top-right-radius($radius);
    @include border-bottom-right-radius($radius);
}

@mixin box-shadow ($shadow...) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    -o-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin force-hw-accel {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0,0,0);
}
@mixin basic-transition($properties...) {
    -webkit-transition: $properties;
    -moz-transition: $properties;
    -o-transition: $properties;
    transition: $properties;

    @include force-hw-accel;
}
@mixin transition($properties...) {
    -webkit-transition: $properties;
    -moz-transition: $properties;
    -o-transition: $properties;
    transition: $properties;
}
@mixin transition-delay($delay...) {
    -webkit-transition-delay: $delay;
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    transition-delay: $delay;
}
@mixin user-select($value) {
    -webkit-touch-callout: $value;
    -webkit-user-select: $value;
    -khtml-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

@mixin search_box {
    input {  outline: none }
    input[type="text"] {
        @include box-sizing(content-box);
        background: white;
        @include border-radius(20px);
        height: 20px;
        border: 1px solid rgba(0,0,0,0.2);
        width: 170px;
        padding: 3px 23px 3px 12px;
    }

    input[type="reset"] {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAGFBMVEUAAAC/v7+/v7+/v7+/v7+/v7+/v7+/v7+hAPa4AAAAB3RSTlMAQNCwkKDgPaU3lgAAAEZJREFUeNplj9EKACAIA2dm+/8/bkj4sI6wDsIpRCRFBprNxx4bD12LrwRSDxRZkCcoCqVDIW1v+9Q+W6sOOhNkY9iQtsIFhO0DtcbsWN0AAAAASUVORK5CYII=');
        width: 14px;
        height: 14px;
        @include border-radius(8px);
        border: 0;
        text-indent: -999px;
        position: absolute;
        right: 8px;
        top: 7px;
        cursor: pointer;
    }

    input[type="text"]:invalid {
        outline: none;
        @include box-shadow(none);
        background: white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAMAAABhq6zVAAAAIVBMVEW/v7+/v78AAAC/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v79QUkolAAAAC3RSTlOLYAAJeoMjRhpOcbsbtd4AAABJSURBVHjaVc1JDsAgDATB9uAF8v8Hx0QcSN9KssZI5eClDj18PRsFlmlQDcfUGd6A3EgYP1xncQ+sa5qYOk9jRQt1Y0izhU5bL2o2AW3ZQKeJAAAAAElFTkSuQmCC') 10px 6px no-repeat;
    }

    input[type="text"]:focus {
        background:white;    
    }
  
    input[type="text"]:invalid + input[type="reset"] {
        visibility:hidden;
    }
}

@mixin clearfix {
    zoom: 1;
    &:before { content: ''; display: block; }
    &:after { content: ''; display: table; clear: both; }
}

@mixin blur($blur) {
    filter: blur($blur);
    -webkit-filter: blur($blur);
    -moz-filter: blur($blur);
    -o-filter: blur($blur);
    -ms-filter: blur($blur);

    filter: url('#svgBlur');
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='13');
}

@mixin flexbox {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin flexbasis($basis) {
    -webkit-flex-basis: $basis;
    -moz-flex-basis: $basis;
    -ms-flex-basis: $basis;
    flex-basis: $basis;
}

@mixin flexrow {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

@mixin flexcol {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@mixin flexrowwrap {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

@mixin flex($kabuki) {
    -webkit-flex: $kabuki;
    -moz-flex: $kabuki;
    -ms-flex: $kabuki;
    flex: $kabuki;
}

@mixin alignitems($alignment) {
    -webkit-align-items: $alignment;
    -moz-align-items: $alignment;
    //-ms-align-items: $alignment;
    -ms-flex-align: $alignment;
    align-items: $alignment;
}

@mixin justifycontent($justification) {
    @if $justification == "flex-end" { 
        -ms-flex-pack: end;
    }
    @if $justification != "flex-end" {
        -ms-flex-pack: $justification;
    }
    -webkit-justify-content: $justification;
    -moz-justify-content: $justification;
    -ms-justify-content: $justification;
    justify-content: $justification;
}

@mixin order($wadookles) {
    -webkit-order: $wadookles;
    -moz-order: $wadookles;
    -ms-order: $wadookles;
    order: $wadookles;
}

@mixin transform($trans) {
    //-ms-transform: $trans;
    //-webkit-transform: $trans;
    //transform: $trans;

    -webkit-transform: $trans;
    -moz-transform: $trans;
    -o-transform: $trans;
    -ms-transform: $trans;
    transform: $trans;
}

@mixin horizontalGradient($from, $to, $c1r, $c1g, $c1b, $o1, $p1, $c2r, $c2g, $c2b, $o2, $p2) {
    // from, to = direction of gradient
    // c1, color 1.
    // c2, color 2.
    // r, g, b prefixed by c1 or c2 = red, green, blue
    // o1, o2 = opacities 1 and 2
    // p1, p2 = gradient terminal positions 1 and 2, in %

    background: -moz-linear-gradient($from,  rgba($c1r,$c1g,$c1b,$o1) $p1, rgba($c2r,$c2g,$c2b,$o2) $p2);
    background: -webkit-gradient(linear, $from top, $to top, color-stop($p1,rgba($c1r,$c1g,$c1b,$o1)), color-stop($p2,rgba($c2r,$c2g,$c2b,$o2))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient($from,  rgba($c1r,$c1g,$c1b,$o1) $p1,rgba($c2r,$c2g,$c2b,$o2) $p2); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient($from,  rgba($c1r,$c1g,$c1b,$o1) $p1,rgba($c2r,$c2g,$c2b,$o2) $p2); /* Opera 11.10+ */
    background: -ms-linear-gradient($from,  rgba($c1r,$c1g,$c1b,$o1) $p1,rgba($c2r,$c2g,$c2b,$o2) $p2); /* IE10+ */
    background: linear-gradient(to $to,  rgba($c1r,$c1g,$c1b,$o1) $p1,rgba($c2r,$c2g,$c2b,$o2) $p2); /* W3C */
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}


@mixin animation($name, $iterations, $duration, $timing_function) {
    -moz-animation-name: $name;
    -moz-animation-iteration-count: $iterations;
    -moz-animation-timing-function: $timing_function;
    -moz-animation-duration: $duration;

    -webkit-animation-name: $name;
    -webkit-animation-iteration-count: $iterations;
    -webkit-animation-timing-function: $timing_function;
    -webkit-animation-duration: $duration;

    animation-name: $name;
    animation-iteration-count: $iterations;
    animation-timing-function: $timing_function;
    animation-duration: $duration;
}

@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
    animation-delay: $delay;
}

@mixin animation-direction($direction) {
    -webkit-animation-direction: $direction;
    -moz-animation-direction: $direction;
    -o-animation-direction: $direction;
    animation-direction: $direction;
}



@mixin pulsingDotsKeyFrames () {
    @keyframes pulsingdots {
        0%{
            transform:scale(1);
            opacity: 1;
        }

        100%{
            transform:scale(.3);
            opacity: 0;
        }
    }

    @-o-keyframes pulsingdots {
        0%{
            -o-transform:scale(1);
            opacity: 1;
        }

        100%{
            -o-transform:scale(.3);
            opacity: 0;
        }
    }

    @-ms-keyframes pulsingdots {
        0%{
            -ms-transform:scale(1);
            opacity: 1;
        }

        100%{
            -ms-transform:scale(.3);
            opacity: 0;
        }
    }

    @-webkit-keyframes pulsingdots {
        0%{
            -webkit-transform:scale(1);
            opacity: 1;
        }

        100%{
            -webkit-transform:scale(.3);
            opacity: 0;
        }
    }

    @-moz-keyframes pulsingdots {
        0%{
            -moz-transform:scale(1);
            opacity: 1;
        }

        100%{
            -moz-transform:scale(.3);
            opacity: 0;
        }
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
    &::placeholder {
        @content
    }
}
