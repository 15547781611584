@import '../../shared/css/styles/mixins';

@media only screen and (-webkit-min-device-pixel-ratio: 2) {

}

@media screen and (max-width: 1320px), screen and (max-device-width: 1320px) {

}

@media (min-width: 1025px) {

}

@media (max-width: 1024px) {

}

@media (min-width: 769px) {
    
}

@media (max-width: 768px) {
    .donate {
        .header-holder {
            .header-bg {
                .header {
                    width: auto;

                    a {
                        >img {
                            //max-width: 300px;
                            //max-height: 80px;
                        }
                    }

                    span.platform {
                        //font-size: 36px;
                        //font-weight: 400;
                    }
                }
            }
        }

        .checkout-holder {
            padding-top: 25px;

            .checkout-content {
                width: auto;
                //margin: 0 auto;

                .checkout-section, .checkout-section.checkout-section-large {
                    min-width: auto;
                    width: calc(100% - 16.5px);
                }
            }
        }

    }
}

@media (max-width: 750px) {
    
}
